import React, { useEffect, useState } from 'react';
import { fetchWithAuth } from '../utils/apiHelper';

const ServerInfo = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const FetchData = async ()=> {
    try {
        const result = await fetchWithAuth('/server-info');
        console.log(result)
        setData(result);    
    } catch (error) {
        console.log(error)
        setError(error)
    }
  }
  useEffect(() => {
    FetchData()
  }, []);

  if (error) return <div className="text-red-500">{error}</div>;

  if (!data) return <div className="text-center text-gray-500">Loading...</div>;

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">Server Information</h2>

      <section className="mb-4">
        <h3 className="text-lg font-semibold">General Info</h3>
        <p><strong>Uptime:</strong> {data.uptime} seconds</p>
        <p><strong>Total Memory:</strong> {(data.memoryUsage.total / (1024 ** 3)).toFixed(2)} GB</p>
        <p><strong>Used Memory:</strong> {(data.memoryUsage.used / (1024 ** 3)).toFixed(2)} GB</p>
        <p><strong>Free Memory:</strong> {(data.memoryUsage.free / (1024 ** 3)).toFixed(2)} GB</p>
        <p><strong>CPU Load:</strong> {data.cpuUsage.currentLoad.toFixed(2)}%</p>
        <p><strong>CPU Temperature:</strong> {data.cpuTemperature.main} °C</p>
      </section>

      <section className="mb-4">
        <h3 className="text-lg font-semibold">Disk Usage</h3>
        <ul className="list-disc list-inside">
          {data.diskUsage.map((disk, index) => (
            <li key={index}>{disk.fs}: {(disk.used / (1024 ** 3)).toFixed(2)} GB used of {(disk.size / (1024 ** 3)).toFixed(2)} GB</li>
          ))}
        </ul>
      </section>

      <section className="mb-4">
        <h3 className="text-lg font-semibold">GPU Info</h3>
        <ul className="list-disc list-inside">
          {data.gpuInfo.controllers.map((gpu, index) => (
            <li key={index}>{gpu.model}</li>
          ))}
        </ul>
      </section>

      <section className="mb-4">
        <h3 className="text-lg font-semibold">Network</h3>
        <ul className="list-disc list-inside">
          {data.networkInterfaces.map((iface, index) => (
            <li key={index}>{iface.iface} - {iface.ip4}</li>
          ))}
        </ul>
        <p><strong>Connections:</strong> {data.networkStats.map(stat => `${stat.iface} - Tx: ${stat.tx_bytes} bytes, Rx: ${stat.rx_bytes} bytes`).join(', ')}</p>
      </section>

      <section className="mb-4">
        <h3 className="text-lg font-semibold">Processes</h3>
        <p><strong>Total Processes:</strong> {data.processes.all}</p>
        <p><strong>Running:</strong> {data.processes.running}</p>
      </section>

      <section className="mb-4">
        <h3 className="text-lg font-semibold">Users Logged</h3>
        <ul className="list-disc list-inside">
          {data.loggedUsers.map((user, index) => (
            <li key={index}>{user.user} - {user.tty} - {new Date(user.date).toLocaleString()}</li>
          ))}
        </ul>
      </section>

      <section className="mb-4">
        <h3 className="text-lg font-semibold">Logs</h3>
        <pre className="bg-gray-800 text-white p-4 rounded overflow-auto max-h-64">{data.logs.join('\n')}</pre>
      </section>
    </div>
  );
};

export default ServerInfo;
